import React, { useEffect } from "react";
import JustGameLogo from "../components/assets/images/just-game-logo.png";
import GooglePlay from "../components/assets/images/google-play.png";
import AppStore from "../components/assets/images/app-store.png";
import Thinktrek from "../components/assets/images/thinktrek.png";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import Header from "./Header";
const Responsible = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleLoad = (data) => {
    console.log(data);
    if (data) {
      window.scroll(0, 0);
    }
  };
  return (
    <>
      <Header props={handleLoad} />
      <section className="privacy_sec">
        <div class="container">
          <div class="row">
            <div class="col-12 text-white fs-5">
              <h2 class="inner-title text-center">
                Responsible Gaming Policy{" "}
              </h2>

              <h4 className="mt-5">1. Introduction</h4>
              <p>
                JustGame is committed to promoting responsible gaming practices.
                We aim to provide an enjoyable and fair gaming environment while
                ensuring players engage with the platform responsibly. This
                policy outlines the measures and guidelines we have in place to
                protect players and minimise the risks associated with excessive
                or harmful gaming behaviour.
              </p>

              <h4 className="mt-5">2. Purpose</h4>
              <p>The purpose of this Responsible Gaming Policy is to:</p>
              <ul>
                <li className="mb-3">Promote a healthy gaming environment.</li>
                <li className="mb-3">
                  Ensure that gaming on JustGame remains an entertainment
                  activity.
                </li>
                <li className="mb-3">
                  Prevent and address the risks of gaming addiction and its
                  associated harm.
                </li>
                <li className="mb-3">
                  Protect vulnerable individuals, including minors.
                </li>
              </ul>

              <h4 className="mt-5">3. Guidelines</h4>
              <ul>
                <li className="mb-3">
                  <strong>Use Only What You Can Afford:</strong> Add funds to
                  your JustGame wallet only from money you can afford. Never use
                  funds meant for essential expenses.
                </li>
                <li className="mb-3">
                  <strong>Plan Your Play:</strong> Pre-set a budget and time
                  limit for your gaming sessions and stick to them, regardless
                  of your gaming performance.
                </li>
                <li className="mb-3">
                  <strong>Stay Emotionally Balanced:</strong> Don't play when
                  you are upset, tired, or stressed. Good decision-making is
                  difficult when your emotional state is imbalanced.
                </li>
                <li className="mb-3">
                  <strong>Avoid Delaying Important Tasks:</strong> Make sure all
                  personal and professional tasks are completed before playing
                  so you can fully enjoy your gaming experience without
                  distractions.
                </li>
                <li className="mb-3">
                  <strong>Balance Gaming With Other Activities:</strong> Ensure
                  gaming is just one of your leisure activities. Engage in other
                  forms of entertainment to maintain balance in your daily life.
                </li>
                <li className="mb-3">
                  <strong>Do Not Borrow Money to Play:</strong> Avoid borrowing
                  money to fund your gaming activities, and never chase losses
                  by increasing your spending or gameplay time.
                </li>
                <li className="mb-3">
                  <strong>Regular Breaks:</strong> Take frequent breaks to avoid
                  fatigue and maintain control over your gameplay.
                </li>
                <li className="mb-3">
                  <strong>Play Within Your Competence Level:</strong> Stick to
                  games and stakes that match your skills and financial
                  capability. Avoid jumping into high-stakes games.
                </li>
                <li className="mb-3">
                  <strong>Age Restrictions:</strong> JustGame strictly enforces
                  a minimum age requirement of 18 years for participation in
                  pay-to - play versions. If we identify underage players, their
                  accounts will be suspended, and any related activities will be
                  voided.
                </li>
              </ul>

              <h4 className="mt-5">4. FairPlay and Transparency</h4>
              <p>
                We strive to create a fair, transparent, and trustworthy gaming
                environment by:
              </p>
              <ul>
                <li className="mb-3">
                  Ensuring that all games hosted on JustGame are based on random
                  outcomes.
                </li>
                <li className="mb-3">
                  Clearly outline each game's rules and terms in text and video
                  format.
                </li>
                <li className="mb-3">
                  Offering customer support to address any concerns related to
                  game fairness [{" "}
                  <a href="mailto:support@justgame.com">support@justgame.com</a>{" "}
                  ]
                </li>
              </ul>

              <h4 className="mt-5">5. Monitoring and Review</h4>
              <p>
                JustGame regularly reviews its responsible gaming measures to
                ensure they are effective and up-to-date. This includes:
              </p>
              <ul>
                <li className="mb-3">
                  Monitoring player activity for signs of excessive or harmful
                  behaviour.
                </li>
                <li className="mb-3">
                  Providing regular updates to this policy in line with
                  regulations and best practice changes.
                </li>
              </ul>

              <h4 className="mt-5">6. Regulatory Compliance</h4>
              <p>
                JustGame complies with all applicable laws and regulations
                related to responsible gaming, including guidelines set forth by
                the Government of India and local regulatory authorities. We
                ensure that our policies and practices meet ethical and
                responsible gaming operations requirements.
              </p>
            </div>
          </div>
        </div>
      </section>

      <Footer props={handleLoad} />
    </>
  );
};

export default Responsible;
