import React, { useState, useEffect } from "react";
import GameData from "../components/Games.json";
import { Link, useNavigate } from "react-router-dom";
import JustGameLogo from "../components/assets/images/just-game-logo.png";

const Header = ({ props }) => {
  const navigate = useNavigate();
  const [isToggle, setIsToggle] = useState(false);
  const scrollToParent = () => {
    // Navigate to the parent route
    navigate("/");

    // Use a small delay to allow the navigation to happen before scrolling
    setTimeout(() => {
      // Find the parent section and scroll into view
      const parentSection = document.querySelector("#about");

      if (parentSection) {
        parentSection.scrollIntoView({ behavior: "smooth" });
      }
    }, 100);
  };
  const scrollToParentHeader = () => {
    // Navigate to the parent route
    navigate("/");

    // Use a small delay to allow the navigation to happen before scrolling
    setTimeout(() => {
      // Find the parent section and scroll into view
      const parentSection = document.querySelector("#our_games");

      if (parentSection) {
        parentSection.scrollIntoView({ behavior: "smooth" });
      }
    }, 100);
  };
  return (
    <header>
      <div className="container">
        <div className="row">
          <div className="col-12 d-flex align-items-center justify-content-center justify-content-sm-between flex-wrap flex-sm-nowrap">
            <Link to="/" onClick={() => window.scrollTo(0, 0)}>
              <img src={JustGameLogo} alt="Just  Game" width="180" />
            </Link>
            <nav className="main-menu">
              <ul>
                <li className="game_all_list">
                  <a onClick={scrollToParentHeader}>
                    Our Games <i class="fa-solid fa-angle-down"></i>
                  </a>
                  <div className="game_menu">
                    {GameData.map((item, key) => {
                      return (
                        <ul>
                          <li
                            onClick={() => {
                              navigate(`/${item?.Game_Name}`, {
                                state: {
                                  data: item,
                                },
                              });
                              props(item?.id);
                            }}
                          >
                            {item?.Game_Name.replace(/-/g, " ")}
                          </li>
                        </ul>
                      );
                    })}
                  </div>
                </li>
                <li>
                  <a onClick={scrollToParent}>About Us</a>
                </li>
                <li>
                  <Link to="/support">Support</Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
