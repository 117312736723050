import React, { useEffect } from "react";
import JustGameLogo from "../components/assets/images/just-game-logo.png";
import GooglePlay from "../components/assets/images/google-play.png";
import AppStore from "../components/assets/images/app-store.png";
import Thinktrek from "../components/assets/images/thinktrek.png";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import Header from "./Header";
const Refund = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleLoad = (data) => {
    console.log(data);
    if (data) {
      window.scroll(0, 0);
    }
  };
  return (
    <>
      <Header props={handleLoad} />
      <section className="terms_sec">
        <div class="container">
          <div class="row">
            <div class="col-12 text-white fs-5">
              <h2 class="inner-title text-center">Refund and Cancellation</h2>

              <h4 className="mt-5">Deposit in User Wallet:</h4>
              <p>
                In compliance with Rule 31B of the Central Goods and Services
                Tax Rules, 2017 (CGST Rules), effective from October 1, 2023,
                JustGame shall levy applicable GST on the amounts deposited by
                the User.
              </p>
              <p>
                The Company will issue an invoice for each deposit, detailing
                the taxable value and applicable GST. If deposits are made via
                UPI, JustGame may store the Virtual Payment Address (VPA) to
                enhance future payment experiences. Users consent to this
                storage.
              </p>
              <p>
                JustGame may retrieve additional VPAs linked to the User's
                registered mobile number to streamline transactions, and Users
                consent to this retrieval and storage.
              </p>

              <h4 className="mt-5">Payment Terms for Games and Contests:</h4>
              <p>
                Users agree to pay contributions for each game, which include:
              </p>
              <ul>
                <li className="mb-3">A Platform Fee for access to the game.</li>
                <li className="mb-3">
                  A Contribution towards the Prize Money Pool for the game,
                  which will be awarded to winners as per the game's rules.
                </li>
                <li className="mb-3">
                  The Company reserves the right to deny access to the platform
                  and disqualify any User at its sole discretion.
                </li>
              </ul>

              <h4 className="mt-5">Role of Payment Collection Agent:</h4>
              <ul>
                <li className="mb-3">
                  Users appoint ___________ (or a designated third party) as
                  their payment collection agent to manage funds on their
                  behalf.
                </li>
                <li className="mb-3">
                  User funds are held in a non-interest-bearing account managed
                  by the Agent.
                </li>
                <li className="mb-3">
                  The Company does not have any right over the Prize Money Pool,
                  which is managed by the Agent, except for its share of
                  Platform Fees.
                </li>
              </ul>

              <h4 className="mt-5">User Account Structure:</h4>
              <ul>
                <li className="mb-3">
                  <strong>Play Money:</strong> Money deposited by the User.
                </li>
                <li className="mb-3">
                  <strong>Prize Money:</strong> Winnings earned by Users, which
                  can be withdrawn or used to play more games, after applicable
                  taxes.
                </li>
                <li className="mb-3">
                  <strong>Cashbacks:</strong> Discounts offered by JustGame.
                  These are non-withdrawable and can be used to avail discounts
                  but cannot be redeemed for cash.
                </li>
                <li className="mb-3">
                  <strong>Bonus Money:</strong> Discounts offered in the form of
                  bonus points. These are non-withdrawable and can only be
                  adjusted against Platform Fees.
                </li>
                <li className="mb-3">
                  <strong>Entry Pass:</strong> A non-withdrawable discount
                  scheme offering Users discounts on entry fees, as per the
                  terms communicated by the Company.
                </li>
                <li className="mb-3">
                  <strong>Special Rewards:</strong> Non-withdrawable discounts
                  offered on certain games or contests, used to avail discounts
                  on entry fees.
                </li>
              </ul>

              <h4 className="mt-5">Withdrawal Process:</h4>
              <ul>
                <li className="mb-3">
                  Users can request withdrawals from their Play Money after
                  completing user verification (including PAN card and bank
                  account verification).
                </li>
                <li className="mb-3">
                  Withdrawals will be processed within a reasonable time after
                  all required documents are verified.
                </li>
                <li className="mb-3">
                  Prize Money withdrawals are subject to a nominal processing
                  fee, and TDS may be applicable as per current tax laws.
                </li>
              </ul>

              <p>
                Pursuant to Rule 31B of the CGST Rules, the amount available for
                withdrawal from the Play Money account will be net of GST on
                deposits made after October 1, 2023.
              </p>

              <p>
                The Company, at its discretion, may issue refunds for
                cancellations or exceptional circumstances, after deducting
                applicable taxes and GST.
              </p>

              <h4 className="mt-5">TDS Deduction:</h4>
              <ul>
                <li className="mb-3">
                  As per the Income Tax Act, 1961, TDS will be deducted on Net
                  Winnings at the time of withdrawal or at the end of the
                  financial year, whichever is earlier.
                </li>
                <li className="mb-3">
                  TDS will be deducted at a rate of 30% on the Net Winnings.
                </li>
                <li className="mb-3">
                  Users will be issued a TDS certificate (Form 16A) for their
                  records.
                </li>
              </ul>

              <h4 className="mt-5">Scheme Terms:</h4>
              <ul>
                <li className="mb-3">
                  Cashback, Bonus Money, and other discount schemes offered by
                  JustGame do not hold any intrinsic value and cannot be
                  redeemed for cash.
                </li>
                <li className="mb-3">
                  The Company reserves the right to modify, cancel, or withdraw
                  any discount schemes or contests without prior notice.
                </li>
              </ul>

              <p>
                The Company reserves the right to disqualify winners or deny
                withdrawals if the User fails to meet eligibility criteria or
                provides false documentation.
              </p>

              <p>
                The User is responsible for any additional taxes applicable
                beyond TDS. The Company is not liable for the quality,
                suitability, or merchantability of any prizes and reserves the
                right to modify or replace prizes at any time.
              </p>

              <h4 className="mt-5">Limitation of Liability</h4>
              <p>
                To the fullest extent permitted by law, users access and use
                JustGame's Services at their own risk. The Services and all
                content are provided on an “AS IS” and “AS AVAILABLE” basis.
                JustGame expressly disclaims all warranties and conditions,
                whether express or implied, including but not limited to,
                implied warranties of merchantability, fitness for a particular
                purpose, or non-infringement. JustGame makes no warranty and
                assumes no responsibility or liability for:
              </p>
              <ul>
                <li className="mb-3">
                  the accuracy, completeness, availability, timeliness,
                  security, or reliability of the Services or any content;
                </li>
                <li className="mb-3">
                  any damage to users’ devices, data loss, or harm arising from
                  accessing or using the Services or content
                </li>
                <li className="mb-3">
                  failure to store, transmit, or delete any content or
                  communication through the Services;
                </li>
                <li className="mb-3">
                  whether the Services will meet user requirements, or be
                  available uninterrupted, secure, or error-free; and
                </li>
                <li className="mb-3">
                  any errors or omissions, including those related to prizes,
                  from JustGame or third parties.
                </li>
              </ul>

              <p>
                To the maximum extent permitted by applicable law, JustGame will
                not be liable for any indirect, incidental, special,
                consequential, or punitive damages, or loss of profits,
                revenues, data, goodwill, or other intangible losses, arising
                from:
              </p>

              <ul>
                <li className="mb-3">
                  access to, use of, or inability to access or use the Services;
                </li>
                <li className="mb-3">
                  any conduct or content of third parties, including defamatory,
                  offensive, or illegal conduct by other users or third parties;
                </li>
                <li className="mb-3">
                  any content obtained from the Services;
                </li>
                <li className="mb-3">
                  unauthorized access, use, or alteration of content; or{" "}
                </li>
                <li className="mb-3">natural calamities.</li>
              </ul>

              <p className="mt-5">
                In no event will JustGame's total liability to any user exceed
                the amount the user has paid to JustGame in the six months
                preceding the claim, or INR 1000, whichever is lower. This
                limitation applies regardless of the legal theory (warranty,
                contract, statute, tort, etc.) and even if JustGame has been
                advised of the possibility of such damages. Users are solely
                responsible for the consequences of accessing and using
                JustGame's Services, especially if such actions involve illegal
                acts or non-compliance with these Terms, including providing
                false information. Users agree to indemnify JustGame, its
                officers, directors, employees, and agents for any losses,
                damages, or legal expenses incurred due to the user's actions,
                including full attorney fees.
              </p>

              <p className="mt-5">
                Users agree to indemnify, defend, and hold JustGame harmless
                from any claims made by third parties arising from their use of
                the Services or participation in any contests. JustGame will not
                be liable for any acts or omissions related to user's engagement
                or contest participation.
              </p>

              <p className="mt-5">
                The failure of JustGame to enforce any provision of these Terms
                does not constitute a waiver of that provision. If any part of
                these Terms is found to be invalid or unenforceable, that
                provision will be limited to the minimum extent necessary, and
                the rest of the Terms will continue to remain in effect.
              </p>

              <p className="mt-5">
                These Terms are governed by the laws of the Republic of India.
                Users acknowledge that their access to the Services may also be
                subject to the laws and regulations specific to their state of
                residence.
              </p>

              <p className="mt-5">
                All legal claims or disputes arising from the use of the
                Services will be subject to the exclusive jurisdiction of the
                courts of Kolkata. Users consent to the jurisdiction and waive
                any objections based on inconvenient forum.
              </p>
            </div>
          </div>
        </div>
      </section>

      <Footer props={handleLoad} />
    </>
  );
};

export default Refund;
